import {Component, OnInit} from '@angular/core';
import {JourneyConfigState} from '@app/state/journey-config';
import {select, Store} from '@ngrx/store';
import {filter, map, takeUntil, tap} from 'rxjs/operators';
import {selectBreadcrumbJourneyParts, selectJourneyConfig} from '@app/state/journey-config/journey-config.selectors';
import {BreadcrumbJourneyPart} from '@app/shared/models/breadcrumb.model';
import {TenantService} from '@app/services/tenant.service';
import { environment } from 'environments/environment';
import { EnvironmentsEnum } from '@app/shared/enums/environments.enum';
import {ActivatedRoute, NavigationEnd, Router, RouterEvent} from '@angular/router';
import { JourneyStepEnum } from '@app/shared/enums/journey-step.enum';
import { mcInRoyPagesPrimaryUserEnum, mcInRoyPagesSecondUserEnum, RootPagesEnum } from '@app/shared/enums/dashboard-pages.enum';
import { setCurrentJourneyConfig } from '@app/state/journey-config/journey-config.actions';
import { JourneyConfig } from '@app/shared/models/journey-config.model';
import { Subject } from 'rxjs';

/** Breadcrumb component for the onboarding journey. */
@Component({
  selector: 'abl-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit{

  env = environment.tenant;
  envEnum = EnvironmentsEnum.mcInroy;
  jouneyConfig: JourneyConfig;
  private destroy$ = new Subject();
  breadcrumbClickOptions: boolean = false;
  breadcrumbJourneyParts$ = this.journeyConfigStore.pipe(
    select(selectBreadcrumbJourneyParts),
    filter(parts => Boolean(parts)),
    map(parts => {
      const filterValues = (parts.flatMap(p => p.stages).some(s => s.isActive || s.isComplete) ? parts : null);
      if(this.env === this.envEnum) {
        this.breadcrumbClickOptions = parts[parts.length - 1]?.stages[1]?.isActive ?false : true;
      }
      return filterValues;
    }),
      // (
      //   parts: BreadcrumbJourneyPart[], // Don't show the journey when no parts are active or complete.
      // ) => (parts.flatMap(p => p.stages).some(s => s.isActive || s.isComplete) ? parts : null),

    );

  currentJourneyConfig$ = this.journeyConfigStore.pipe(
    select(selectJourneyConfig),
    tap(c => (this.jouneyConfig = c)),
    takeUntil(this.destroy$),
  );

  constructor(
    private journeyConfigStore: Store<JourneyConfigState.JourneyConfigState>,
    public tenantService: TenantService,
    private router: Router,
  ) {}

  getColor() {
    if(this.env === this.envEnum) {
      if(this.router.url.includes(JourneyStepEnum.Gdpr)) {
        return '#F2ECDF';
      }
      else {
        return '#F2ECDF';
      }

    } else {
      return '#4a4f55';
    }
  }

  ngOnInit() {
    this.currentJourneyConfig$.subscribe();
  }
  ngOnDestroy() {
    // Unsubscribe from all subscriptions when the component is destroyed.
    this.destroy$.next();
    this.destroy$.complete();
  }


  navigateFromBreadcrumb(stage) {
    if(this.env == this.envEnum && stage.isComplete) {
      var journey = this.jouneyConfig;
      if(journey.id == 1){
        this.journeyConfigStore.dispatch(setCurrentJourneyConfig({journeyConfig:journey, currentStep: mcInRoyPagesPrimaryUserEnum[stage.displayName]}));
      }
      if(journey.id == 2) {
        this.journeyConfigStore.dispatch(setCurrentJourneyConfig({journeyConfig:journey, currentStep: mcInRoyPagesSecondUserEnum[stage.displayName]}));
      }
    }
  }
}
