import {Injectable} from '@angular/core';
import {Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {combineLatest, Observable, Subject} from 'rxjs';
import {Store} from '@ngrx/store';
import {MandateState} from '@app/state/mandate/mandate.state';
import {
  selectAllMandates,
  selectHasMandates,
  selectIsGDPRCompleted,
  selectIsPrincipal,
  selectMandatesLoading,
} from '@app/state/mandate/mandate.selectors';
import {filter, first, map, skip, skipWhile, switchMap} from 'rxjs/operators';
import {JourneyStepEnum} from '@app/shared/enums/journey-step.enum';
import {environment} from '../environments/environment';
import {ProfileState} from '@app/state/profile/profile.state';
import {AuthOidcService} from '@app/auth/services/auth-oidc.service';
import {fetchMandates} from '@app/state/mandate/mandate.actions';
import {fetchProfile} from '@app/state/profile/profile.actions';
import {DashboardPagesEnum, RootPagesEnum} from '@app/shared/enums/dashboard-pages.enum';
import {PortfolioState} from '@app/state/portfolio/portfolio.state';
import {fetchPortfolios} from '@app/state/portfolio/portfolio.actions';
import {OAuthStorageService} from '@app/auth/services/oauth-storage.service';
import {AttitudeToRiskState} from './state/attitude-to-risk/attitude-to-risk.state';
import {fetchRiskquestionnaireScore} from './state/attitude-to-risk/attitude-to-risk.actions';
import {fetchRiskquestionnaireScoreResponse} from '@app/state/attitude-to-risk/attitude-to-risk.selectors';
import {OnboardingState} from './state/onboarding';
import {continueApplication, setOnboardingStateForMandate} from './state/onboarding/onboarding.actions';
import {EnvironmentsEnum} from './shared/enums/environments.enum';
import { selectProfile, selectProfileLoading } from './state/profile/profile.selectors';

@Injectable({
  providedIn: 'root',
})
export class CanActivateDashboardGuard {
  destroy$ = new Subject();
  onBoardingOnly = environment.onBoardingOnly;
  env = environment.tenant;
  zeroMandateRoute = environment.zeroMandateRouteEnabled;
  envEnum = EnvironmentsEnum.mcInroy;

  private mandatesLoading$ = this.mandateStore.select(selectMandatesLoading);
  private profileLoading$ = this.profileStore.select(selectProfileLoading);
  private hasMandates$ = this.mandateStore.select(selectHasMandates);
  isIgnore = this.oauthStorageService.setItem('isIgnore', 'false');
  // private hasActivePortfolios$ = this.portfolioStore.select(selectHasActivePortfolios);
  // private createfetchPortfoliosSuccess$ = this.portfolioStore.select(fetchPortfoliosSuccessful);
  private mandates$ = this.mandateStore.select(selectAllMandates);
  private isPrincipal$ = this.mandateStore.select(selectIsPrincipal);
  private isGDPRCompleted$ = this.mandateStore.select(selectIsGDPRCompleted);
  private fetchRiskquestionnaireScoreResponse$ = this.attitudeToRiskStore.select(fetchRiskquestionnaireScoreResponse);
  private selectProfile$ = this.profileStore.select(selectProfile);
  //private selectSecurityQuestionsAnswered$ = this.profileStore.select(selectSecurityQuestionsAnswered);

  canNavigate$ = combineLatest([
    this.isPrincipal$,
    this.isGDPRCompleted$,
    this.hasMandates$,
    this.mandatesLoading$,
    this.profileLoading$,
    this.selectProfile$.pipe(
      skip(1),
    )
  ]).pipe(
    skipWhile(([, , ,loading]) =>  loading),
    map(([isPrincipal, isGDPRCompleted, hasMandates, , ,selectProfile ]) => {
      if(this.env === this.envEnum){
        if(!selectProfile.emailConfirmed) {
          return this.router.createUrlTree([`/onboarding/${JourneyStepEnum.EmailVerification}`])
        }
        if(selectProfile.emailConfirmed && !selectProfile.securityQuestionsAnswered) {
          return this.router.createUrlTree([`/onboarding/${JourneyStepEnum.SecurityQuestion}`])
        } 
        if (!hasMandates && this.zeroMandateRoute && selectProfile.emailConfirmed && selectProfile.securityQuestionsAnswered && !isGDPRCompleted) {
          return this.router.createUrlTree([`/onboarding/${JourneyStepEnum.Gdpr}`]);
        }
        if (this.zeroMandateRoute && selectProfile.emailConfirmed && selectProfile.securityQuestionsAnswered && !isGDPRCompleted) {
          return this.router.createUrlTree([`/onboarding/${JourneyStepEnum.Gdpr}`]);
        }
        if (this.env === this.envEnum && hasMandates && !isPrincipal && !isGDPRCompleted && selectProfile.emailConfirmed && selectProfile.securityQuestionsAnswered) {
          return this.router.createUrlTree([`/onboarding/${JourneyStepEnum.Gdpr}`]);
        } else if (hasMandates && this.onBoardingOnly) {
          this.mandates$.subscribe(mandates => {
            this.onboardingStore.dispatch(continueApplication({mandateId: mandates[0].id}));
          });
        }
      }
      else{
        if (!hasMandates && this.zeroMandateRoute) {
          return this.router.createUrlTree([`/onboarding/${JourneyStepEnum.Gdpr}`]);
        }
      }

      return hasMandates;
    }),
  );

  constructor(
    private authService: AuthOidcService,
    private mandateStore: Store<MandateState>,
    private portfolioStore: Store<PortfolioState>,
    private profileStore: Store<ProfileState>,
    private attitudeToRiskStore: Store<AttitudeToRiskState>,
    private router: Router,
    private oauthStorageService: OAuthStorageService,
    private onboardingStore: Store<OnboardingState.OnboardingState>,
  ) {
    if (this.authService.hasValidToken) {
      this.attitudeToRiskStore.dispatch(fetchRiskquestionnaireScore());
      const includeConnectedAccounts = false;
      // route to accounts or whatever
      this.portfolioStore.dispatch(fetchPortfolios({includeConnectedAccounts}));
      // if (this.createfetchPortfoliosSuccess$) {
      this.profileStore.dispatch(fetchProfile());
      this.mandateStore.dispatch(fetchMandates());

      // }
    }
  }

  canActivate(_, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.canNavigate$.pipe(
      switchMap(urlTree => {
        return this.mandates$.pipe(
          switchMap(mandates => {
            if (mandates && mandates.length > 0) {
              this.onboardingStore.dispatch(setOnboardingStateForMandate({mandateId: mandates[0].id}));
              if(this.env == this.envEnum) {
                window.sessionStorage.setItem('principal_value', mandates[0].isPrincipal.toString());
              //  window.sessionStorage.setItem('mandate_id', mandates[0].id.toString());
              }
            }
            return this.fetchRiskquestionnaireScoreResponse$.pipe(
              filter(x => !!x),
              map(res => {
                const paymentId = this.oauthStorageService.getItem('paymentId');
                let isPaymentFlow = false;

                if (paymentId) {
                  if (paymentId != 'null') {
                    isPaymentFlow = true;
                  }
                }

                if (res.isChangeMandatory == undefined) {
                  res.isChangeRequired = JSON.parse(this.oauthStorageService.getItem('isChangeRequired'));
                  res.lastChangedMonth = Number(this.oauthStorageService.getItem('lastChangedMonth'));
                  res.isChangeMandatory = JSON.parse(this.oauthStorageService.getItem('isChangeMandatory'));
                  res.directDebitClearDownDate = new Date(this.oauthStorageService.getItem('directDebitClearDownDate'));
                }

                const isSkipClicked = this.oauthStorageService.getItem('isSkipClicked');

                this.oauthStorageService.setItem('isChangeRequired', (res?.isChangeRequired ?? false).toString());
                this.oauthStorageService.setItem('isChangeMandatory', (res?.isChangeMandatory ?? false).toString());
                this.oauthStorageService.setItem('lastChangedMonth', (res?.lastChangedMonth ?? 0).toString());
                this.oauthStorageService.setItem(
                  'directDebitClearDownDate',
                  (res?.directDebitClearDownDate == null ? '' : res.directDebitClearDownDate.toString()).toString(),
                );

                if ((!isPaymentFlow && res.isChangeRequired && res.isChangeMandatory) || res.isChangeMandatory) {
                  //show new page and skip disabled
                  this.oauthStorageService.setItem('isSkipEnabled', 'false');
                  this.router.navigate([`${RootPagesEnum.Risk}/risk-questionnaire-retake-screen`]);
                } else if (
                  !isPaymentFlow &&
                  res.isChangeRequired &&
                  !res.isChangeMandatory &&
                  isSkipClicked != 'true'
                ) {
                  this.oauthStorageService.setItem('isSkipEnabled', 'true');

                  this.router.navigate([`${RootPagesEnum.Risk}/risk-questionnaire-retake-screen`]);
                  /// show new page and skip is available
                } else {
                  this.oauthStorageService.setItem('isSkipEnabled', 'null');
                  this.oauthStorageService.setItem('isChangeRequired', 'null');
                  this.oauthStorageService.setItem('isChangeMandatory', 'null');
                  this.oauthStorageService.setItem('isSkipClicked', 'false');
                  this.oauthStorageService.setItem('lastChangedMonth', 'null');
                  this.oauthStorageService.setItem('directDebitClearDownDate', 'null');

                  const portfoliosIsActive = mandates.some(m => m.portfolioIsActive);
                  const isIgnore = this.oauthStorageService.getItem('isIgnore');
                  if (isIgnore == 'true') {
                    return urlTree;
                  }
                  if (portfoliosIsActive && state.url === `/${DashboardPagesEnum.Applications}/All`) {
                    // We want to navigate to the accounts' page when there are completed mandates.
                    return this.router.createUrlTree([`/${DashboardPagesEnum.Accounts}`]);
                  }
                }
                return urlTree;
              }),
            );
          }),
          first(),
        );
      }),
    );

    // return combineLatest([this.canNavigate$, this.mandates$, this.mandatesLoading$]).pipe(
    //   skipWhile(([, , loading]) => loading),
    //   map(([urlTree, mandates]) => {
    //     console.log(urlTree, mandates);
    //     // let portfoliosIsActive = false;
    //     // if (!!mandates.length === false && environment.zeroMandateRouteEnabled) {
    //     //   return this.router.createUrlTree([`/onboarding/${JourneyStepEnum.Gdpr}`]);
    //     // } else {
    //     const portfoliosIsActive = mandates.some(m => m.portfolioIsActive);
    //     // }
    //     console.log(portfoliosIsActive, state.url);
    //     if (portfoliosIsActive && state.url === `/${DashboardPagesEnum.Applications}/All`) {
    //       // We want to navigate to the accounts' page when there are completed mandates.
    //       return this.router.createUrlTree([`/${DashboardPagesEnum.Accounts}`]);
    //     }
    //     return urlTree;
    //   }),
    //   first(),
    // );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
